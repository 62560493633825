/* syntax-highlighting.css */

/* Reset highlight.js styles */
.hljs {
  border: none !important;
  padding: 0 !important;
  background: transparent !important;
}

/* Light Mode Variables */
:root {
  --syntax-bg: #f5f5f5;
  --syntax-text: #24292e;
  --syntax-comment: #6a737d; /* Darker color for comments */
  --syntax-string: #032f62;  /* Darker color for strings */
  /* Add more overrides as needed */
}

/* Dark Mode Variables */
.dark {
  --syntax-bg: #0d1117; /* Darker background for dark mode */
  --syntax-text: #c9d1d9;
  --syntax-comment: #8b949e;
  --syntax-string: #58a6ff;
  /* Add more overrides as needed */
}

/* Style for pre elements */
.prose pre {
  background-color: var(--syntax-bg) !important;
  color: var(--syntax-text) !important;
  padding: 0.75em; /* Adjusted padding */
  border-radius: 6px; /* Adjusted border-radius */
  overflow-x: auto;
  border: 1px solid #e2e8f0; /* Light border */
}

.dark .prose pre {
  border: 1px solid #2d3748; /* Dark border for dark mode */
}

/* Ensure hljs within pre has transparent background */
.prose pre .hljs {
  background-color: transparent !important;
}

/* Syntax Highlighting Overrides */
.hljs-comment,
.hljs-quote {
  color: var(--syntax-comment);
}

.hljs-string,
.hljs-title,
.hljs-section {
  color: var(--syntax-string);
}

/* Add more syntax element customizations as needed */