/* src/index.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'katex/dist/katex.min.css';

/* Custom CSS Variables */
:root {
  --color-bg-dark: #1a1b26;
  --color-text-dark: #e5e5e5;
  --color-primary-dark: #7c3aed;
}

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Default Light Mode */
  background-color: #f9fafb; /* Tailwind's gray-50 */
  color: #1f2937; /* Tailwind's gray-800 */

  /* Dark Mode */
  @apply dark:bg-gray-900 dark:text-gray-100;

  /* Smooth scroll behavior */
  scroll-behavior: smooth;

  /* Prevent body from scrolling */
  /* overflow: hidden; */ /* Ensure this is commented out */
}

/* Dark Mode Customizations */
.dark {
  --tw-bg-opacity: 1;
  background-color: var(--color-bg-dark);
  color: var(--color-text-dark);
}

.dark .text-primary {
  color: var(--color-primary-dark);
}

.dark .bg-primary {
  background-color: var(--color-primary-dark);
}

/* Dot pattern overlay */
.dot-pattern {
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
}

.dark .dot-pattern {
  background-image: radial-gradient(circle, rgba(255, 255, 255, 0.05) 1px, transparent 1px);
}

/* Animations */
.fade-in {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.document-preview-enter {
  opacity: 0;
}

.document-preview-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.document-preview-exit {
  opacity: 1;
}

.document-preview-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.katex {
  color: inherit;
}

/* Custom Styles for Equations */
.equation-wrapper {
  @apply bg-gray-100 dark:bg-gray-700 p-4 rounded shadow-md my-4;
}

.equation-wrapper .katex-display {
  @apply mx-auto;
}

/* Center-align equations */
.katex-display {
  text-align: center;
}

/* Custom Styles for Tables */
.custom-table {
  @apply w-full table-auto border-collapse rounded-lg shadow-lg bg-white dark:bg-gray-800;
}

.custom-table thead {
  @apply bg-gray-200 dark:bg-gray-700;
}

.custom-table tbody tr:nth-child(even) {
  @apply bg-gray-50 dark:bg-gray-700;
}

.custom-table tbody tr:hover {
  @apply bg-gray-100 dark:bg-gray-600;
}

/* Custom Styles for List Bullets */
.custom-list {
  list-style: none;
  padding-left: 1.5em;
}

.custom-list li {
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 0.5em;
}

.custom-list li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.4em;
  width: 0.5em;
  height: 0.5em;
  background-image: url('../public/custom-bullet.svg'); /* Replace with your SVG path */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  /* For subtle color, you can use a gradient or a solid color */
  background-color: #6366f1; /* Tailwind's indigo-500 */
  border-radius: 50%;
}

/* Dark Mode Adjustments for List Bullets */
.dark .custom-list li::before {
  background-color: #818cf8; /* Tailwind's indigo-400 */
}

/* Ensure all other elements look good in dark mode */
.dark .equation-wrapper {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.dark .custom-table {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}